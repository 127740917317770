    /** reset **/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
svg,
iframe {
  display: block;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}
*::after {
  box-sizing: border-box;
}
*::before {
  box-sizing: border-box;
}
* :invalid {
  box-shadow: none;
}
* :-moz-submit-invalid {
  box-shadow: none;
}
* :-moz-ui-invalid {
  box-shadow: none;
}
* :focus {
  outline: none;
}

.highlights {
  color: transparent;
}

a {
  color: inherit;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

strong,
b {
  font-weight: 700;
}

html,
body {
  height: 100%;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3; 
}

.slot_wrapper {
  display: flex;
  flex-direction: row;
  overflow: visible;
  flex-flow: column;
}

.section_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: visible;
  background: #eee;
}

.wrapper {
  padding-left: 85px;
  transition: 0.4s padding-left;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.wrapper__open {
  padding-left: 315px;
  transition: 0.4s padding-left;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.appmenu__open {
  width: 300px;
}

.appmenu {
  width: 40px;
}

.shadow {
  position: fixed;
  width: 125vw;
  height: 125vh;
  top: 0;
  left: 0;
  z-index: 9990;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.shadow_circle {
  margin-top: -25px;
  margin-left: -25px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
}

header,
footer {
  flex: 0 0 auto;
}

main {
  flex: 1 0 auto;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  min-width: 320px;
  line-height: normal;
  color: #243341;
  background-color: #f4f6f8;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
}

h2 {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
}

h3 {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

h4 {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

h5 {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

h6 {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.visually--hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.object-fit {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.object-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.is--cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.is--contain {
  -o-object-fit: contain;
  object-fit: contain;
}

input,
select {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #243341;
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

textarea {
  width: 100%;
  height: 215px;
  overflow: hidden auto;
  resize: none;
  scrollbar-color: #6c7a7d transparent !important;
  scrollbar-width: thin !important;
}

textarea::-webkit-scrollbar {
  width: 8px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #6c7a7d;
  border-radius: 0;
}

/** placeholders **/
input::-moz-placeholder,
textarea::-moz-placeholder {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  opacity: 1;
  color: #6c7a7d;
  font-size: 14px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  opacity: 1;
  color: #6c7a7d;
  font-size: 14px;
}

input:not([type="submit"]) {
  width: 100%;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
  outline: none;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
  outline: none;
}

select {
  cursor: pointer;
  padding-right: 30px !important;
  background: url("../img/select.svg") no-repeat calc(100% - 15px) center;
}

@-moz-document url-prefix() {
  select {
    text-indent: -2px;
  }
}
option {
  padding: 0;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
  -webkit-appearance: none;
}

input[type="submit"]::-moz-focus-inner {
  border: 0;
}

/** button **/
button {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  padding: 3;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
}

.button_bordered {
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
  padding: 3;
  border: 1;
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
}

button::-moz-focus-inner {
  border: 0;
}

.section_head {
  background-color: #eee;
  justify-contennt: space-between;
  transition: 0.4s max-height;
  max-height: 60px;
}

.section_arrow-down {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
}

.section_arrow-down-small {
  width: 0;
  height: 0;
  cursor: pointer;
  margin-top: 16px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black;
}

.section_arrow-up-small {
  width: 0;
  height: 0;
  cursor: pointer;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
}

.section_arrow-right {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;

  border-left: 4px solid black;
}

.section_arrow {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-left-color: gray;
  transform-origin: 0 50%;
  transition: transform 0.25s;
  cursor: pointer;
}

.section_arrow_collapse {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-left-color: gray;
  transform-origin: 0 50%;
  transition: transform 0.25s;
  cursor: pointer;
}

/** header **/

.header {
  padding: 30px;
}

.header__nav {
  padding: 40px 30px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #0c2441;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  flex-direction: column;
  width: 315px;
}

.header__logo {
  margin-left: 16px;
}

.header__nav-row {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  padding-left: 20px;
}

.profile {
  display: flex;
  align-items: center;
}

.text_n {
  white-space: pre-line;
}

.profile__img {
  flex: 0 0 auto;
  margin-right: 16px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid lightgray;
}

.profile__name {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}

span.profile .profile__name {
  font-weight: 600;
}

.block-dropdown,
.lang {
  position: relative;
}

.lang__btn {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #243341;
}

.lang__icon {
  flex: 0 0 auto;
  margin-left: 7px;
  stroke: #243341;
}

.lang__flag {
  flex: 0 0 auto;
  margin-right: 9px;
}

.block-dropdwon__btn {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #156ae9;
  text-transform: uppercase;
  padding: 7px 27px 7px 10px;
  letter-spacing: 0.1em;
  font-weight: bold;
  font-size: 8px;
  color: #156ae9;
  position: relative;
}

.block-dropdwon__icon {
  position: absolute;
  right: 12px;
  top: 8px;
  stroke: #156ae9;
}

.dropdown {
  position: relative;
  padding-right: 40px !important;
}

.dropdown__icon {
  fill: none;
  position: absolute;
  top: 23px;
  right: 10px;
  stroke: #fff;
}

.dropdown:hover .dropdown__icon {
  stroke: #36b2f1;
}

.header__sublist {
  margin-top: 10px;
  padding-left: 30px;
  padding-bottom: 10px;
}

.header__iconitem {
  position: relative;
  padding-left: 2px;
}

.header__iconitem:not(:last-of-type) {
  margin-bottom: 6px;
}

.header__subitem {
  position: relative;
  padding-left: 32px;
}

.header__subitem:not(:last-of-type) {
  margin-bottom: 4px;
}

.header__subitem:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
}

.header__sublink {
  font-size: 13px;
  font-weight: 500;
}

.sandwich {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 24px;
  height: 20px;
  justify-content: space-between;
}

.sandwich__part {
  height: 2px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}

.header__list {
  font-weight: 600;
  color: #fff;
  overflow: hidden auto;
  margin-right: -25px;
  padding-right: 25px;
  scrollbar-color: #fff transparent !important;
  scrollbar-width: thin !important;
}

.header__list::-webkit-scrollbar {
  width: 8px;
}

.header__list::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 0;
}

.header__item:not(:last-of-type) {
  margin-bottom: 13px;
}

.header__link {
  padding: 13px 20px 14px;
  display: flex;
  align-items: flex-start;
  line-height: 143%;
  fill: #fff;
  border-radius: 8px;
}

.header__link span {
  padding-top: 2px;
}

.header__link:hover {
  color: #36b2f1;
  fill: #36b2f1;
  background-color: #0f2d51;
}

.svg-stroke {
  stroke: #fff;
}

.link--active .svg-stroke {
  stroke: #36b2f1;
}

.header__link:hover .svg-stroke {
  stroke: #36b2f1;
}

.header__link svg {
  flex: 0 0 auto;
  margin-right: 15px;
}

.link--active {
  color: #36b2f1;
  fill: #36b2f1;
  background-color: #0f2d51;
  pointer-events: none;
}

.link--active.dropdown .dropdown__icon {
  stroke: #36b2f1;
}

.header__blocks {
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 3px 35px rgba(36, 51, 65, 0.03);
  height: 60px;
  padding: 5px 5px;
}

.header__block {
  flex: 0 0 auto;
  padding: 0 10px;
}

.header__form {
  flex: 1;
}

.header__btn,
.header__form-submit {
  stroke: #243341;
}

.header__form-search {
  display: flex;
  align-items: center;
}

.header__form-submit {
  flex: 0 0 auto;
  margin-right: 5px;
  margin-left: 5px;
}

.header__form-input {
  padding: 6px 0;
}

/** layout **/
.layout {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.layout_main {
}

.layout__contains {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-top: 34px;
}

.layout__btn {
  max-width: 282px;
  flex: 1;
}

.layout__contains .layout__title {
  margin-bottom: 0;
  margin-right: 30px;
}

.layout__title {
  margin-bottom: 10px;
  height: 100%;
}

.layout__wrap {
  overflow: hidden;
}

.layout__cols {
  display: flex;
  margin: 0 -15px;
}

.layout__col {
  padding: 0 15px;
  flex: 1;
}

.layout__col-aside {
  max-width: 472px;
  min-width: 330px;
}

.layout__block {
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.layout__block-title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.layout__block-monitor {
  background: #f4f6f8;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 387px;
  height: 221px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  margin-bottom: 23px;
}

.layout__block-label {
  font-weight: 700;
  font-size: 8px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #243341;
  position: absolute;
  top: 17px;
  right: 13px;
  padding: 6px 8px;
  background-color: #fff;
  border-radius: 6px;
  z-index: 1;
}

.layout__inf-title {
  font-weight: 500;
  font-size: 18px;
  color: #243341;
  margin-bottom: 12px;
}

.layout__inf-list {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
  padding-bottom: 19px;
}

.layout__inf-item {
  padding: 3px;
}

.layout__inf-label {
  background: linear-gradient(90deg, #2ae9d2 -23.82%, #51d5cd 102.61%);
  border-radius: 6px;
  padding: 6px 12px;
  font-weight: 700;
  font-size: 8px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  display: block;
}

.layout__inf-text {
  margin-bottom: 10px;
}

.layout__inf-blocks {
  display: flex;
  flex-wrap: wrap;
  padding-top: 9px;
  padding-bottom: 10px;
  margin: -7px;
}

.layout__inf-block {
  padding: 7px;
  width: calc(100% / 2);
}

.layout__inf-media {
  background-color: #fff;
  border: 1px solid #daebef;
  border-radius: 10px;
  padding: 14px 15px 16px;
  display: flex;
  align-items: flex-start;
}

.layout__inf-media svg {
  flex: 0 0 auto;
  margin-right: 16px;
  fill: #0f2d51;
  stroke: #0f2d51;
}

.layout__teams-title {
  font-weight: 600;
  font-size: 14px;
}

.layout__block-btns {
  margin-top: auto;
  padding-top: 30px;
}

.layout__block-btn:not(:last-of-type) {
  margin-bottom: 13px;
}

/** btn **/
.btn {
  display: block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: linear-gradient(90deg, #4bebd8 -23.82%, #2b95ff 102.61%);
  border-radius: 10px;
  padding: 17px 15px;
  color: #fff;
}

.btn_disabled {
  display: block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: linear-gradient(90deg, #888888 -23.82%, #cccccc 102.61%);
  border-radius: 10px;
  padding: 17px 15px;
  color: #fff;
}

.btn--border {
  color: #28b2d2;
  background: #fff;
  padding: 16px 15px;
  border: 1px solid #3dc7e8;
}

/** breadcrumbs **/
.breadcrumbs-wrap {
  overflow: hidden;
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
  color: #6c7a7d;
  padding-bottom: 26px;
}

.breadcrumbs__item {
  padding: 6px 6px;
}

a.breadcrumbs__item {
  text-decoration: underline;
}

a.breadcrumbs__item:hover {
  text-decoration: none;
}

/** form **/
.form {
  display: flex;
  margin: 0 -30px;
}

.form__col {
  padding: 0 30px;
  flex: 1;
}

.form__col-small {
  max-width: 444px;
}

.form__label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 14px;
  display: inline-block;
  white-space: nowrap;
}

/** file **/
.file {
  position: relative;
}

.file__part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 249px;
  background-color: #f9fafb;
  border-radius: 10px;
  border: 1px dashed #dbe6ef;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  color: #6c7a7d;
}

.file__part svg {
  fill: #3ec8e7;
  stroke: #3ec8e7;
  margin-bottom: 18px;
  overflow: visible;
}

.file__part span {
  color: #28b2d2;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  padding-bottom: 8px;
}

.form__group {
  margin-bottom: 29px;
}

.form__input,
input,
select {
  background-color: #fff;
  border: 1px solid #a9bbcb;
  border-radius: 10px;
  padding: 15px;
}

input,
select {
  background-color: transparent;
}

.form__group-small {
  max-width: 100px;
}

.radio-buttons {
  border: 1px solid #3dc7e8;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
}

.radio-button {
  position: relative;
  flex: 1;
}

.radio-button__part {
  display: block;
  padding: 15px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #243341;
  cursor: pointer;
  border-radius: 10px;
}

.radio-button__checked {
  display: block;
  padding: 15px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 10px;
  background: linear-gradient(117.47deg, #4bebd8 2.19%, #2b95ff 91.03%);
  color: #fff;
}

.radio-button input:checked + .radio-button__part {
  background: linear-gradient(117.47deg, #4bebd8 2.19%, #2b95ff 91.03%);
  color: #fff;
}

.form__btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #28b2d2;
  font-weight: 400;
}

.form__btn span {
  white-space: nowrap;
  display: block;
  border-bottom: 1px solid #28b2d2;
}

.form__btn:hover span {
  border-bottom: 1px solid transparent;
}

.form__group-top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: -8px;
  margin-bottom: -8px;
  padding-bottom: 12px;
}

.form__group-body {
  padding: 15px 0;
  border-top: 1px solid #dbe6ef;
  border-bottom: 1px solid #dbe6ef;
}

.form__group-top .form__label {
  margin-bottom: 0;
  margin-right: 15px;
  padding: 8px 0;
}

.form__btn svg {
  margin-right: 10px;
  flex: 0 0 auto;
  stroke: #28b2d2;
  fill: #28b2d2;
  visibility: visible;
}

.date-wrap,
.time-wrap {
  width: 190px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #243341;
}

.form__group-flex {
  display: flex;
  align-items: center;
}

.form__group-flex label {
  margin-right: 20px;
  flex: 0 0 auto;
  white-space: nowrap;
}

.time-wrap,
.date-wrap {
  width: 300px;
}

.date-wrap label,
.date-wrap span,
.time-wrap label,
.time-wrap span {
  display: block;
  margin-right: 15px;
  flex: 0 0 auto;
}

.form__group-row {
  flex: 1;
  padding: 10px;
}

.form__group-rows {
  display: flex;
  margin: -10px;
  align-items: center;
}

.form__group-rows:not(:last-of-type) {
  padding-bottom: 5px;
}

.form__remove {
  fill: none;
}

/** calendar **/
.calendar {
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  border-radius: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  overflow-y: hidden;
}

.calendar__head {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #dbe6ef;
  padding: 0 20px 20px;
  position: relative;
}

.calendar__nav {
  display: flex;
  align-items: center;
}

.calendar__nav-text {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #243341;
  padding: 0 24px;
}

.calendar__nav-arrow {
  stroke: #243341;
  fill: none;
}

.calendar__dropdown {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 30px;
  fill: #243341;
}

.calendar__dropdown svg {
  flex: 0 0 auto;
  margin-right: 15px;
}

.calendar__dropdown span {
  font-size: 14px;
  line-height: 17px;
  color: #243341;
  text-decoration: underline;
  flex: 0 0 auto;
}

.calendar__body {
  overflow: hidden;
  height: 100%;
}

.calendar__tbody_full {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 230px);
  touch-action: none;
}

.calendar__tbody_small {
  overflow-y: auto;

  overflow-x: hidden;
  height: calc(100vh - 570px);
  touch-action: none;
}

.calendar__body-rows {
  display: flex;
  align-items: center;
  padding: 16px 30px;
  margin: 0 -10px;
  position: relative;
}

.row-max-height {
  height: 100%;
}

.calendar__body-rows:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  border-bottom: 1px solid #dbe6ef;
}

.calendar__body-head {
  font-weight: 600;
  font-size: 14px;
  color: #243341;
}

.calendar__body-row {
  padding: 0 15px;
  max-height: 100%;
}

.calendar__body-row:first-of-type {
  width: 140px;
  flex: 0 0 auto;
  padding: 0 20px;
}

.calendar__body-row:nth-of-type(2) {
  max-width: 120px;
  flex: 1;
  min-width: 120px;
  padding: 0 10px;
}

.calendar__body-row:nth-of-type(3) {
  max-width: 100px;
  flex: 1;
  min-width: 80px;
  padding: 0 20px 0 0;
}

.calendar__body-row:nth-of-type(4) {
  max-width: 600px;
  min-width: 500px;
  padding-left: 13px;
}

.calendar__body-row:nth-of-type(5) {
  max-width: 800px;
  min-width: 600px;
  padding-left: 20px;
}

.calendar__body-row:nth-of-type(6) {
  max-width: 250px;
  min-width: 200px;
  flex: 0 0 auto;
}

.calendar__body-time {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.15em;
  color: #243341;
  display: block;
  margin-bottom: 6px;
}

.calendar__body-label {
  font-weight: bold;
  font-size: 8px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #38beb3;
  padding: 5px 9px;
  border: 1px solid #4cd2c7;
  border-radius: 6px;
}

.calendar__body-label.is--gray {
  color: #a9bbcb;
  border-color: #a9bbcb;
}

.calendar__body-block {
  /*min-height: 50px;*/
  /*max-height: 100%;*/
  /*display: flex;*/
  /*align-items: center;*/
  /*border: 1px dashed #a9bbcb;*/
  /*border-radius: 10px;*/
  /*padding: 7px;*/
  border: 1px solid #daebef;
  background: #f4f6f8;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px 10px;
  color: #243341;

  cursor: pointer;
  line-height: 14px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  display: block;
}

.calendar__body-block .input {
  padding: 0;
  background: none;
  box-shadow: none;
  border: none;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 4px;
}

.item-edit {
  display: flex;
  flex-direction: row;
}

.input-wrap {
  width: 100%;
  position: relative;
}

.input {
  padding: 10px 30px 10px 10px;
  background: #f4f6f8;
  border: 1px solid #daebef;
  box-shadow: -3px -2px 5px rgba(169, 187, 203, 0.15),
    inset -2px -2px 0px #e3edf5, inset 2px 2px 1px #ffffff;
  border-radius: 5px;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.input-remove {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.calendar-blocks__block:not(:last-of-type) {
  padding-bottom: 5px;
}

.calendar-block {
  background: #f4f6f8;
  border: 1px solid #daebef;
  border-radius: 10px;
  padding: 17px 12px;
  min-height: 58px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 44px;
  position: relative;
}

.calendar-block__lock {
  width: 16px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -32px;
}

.calendar-block:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -50px;
  height: 1px;
  border-bottom: 1px solid #daebef;
  width: 50px;
}

.calendar-block__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.calendar-block__name {
  font-weight: bold;
  font-size: 12px;
}

.calendar-block__col {
  font-weight: 600;
  font-size: 10px;
  padding: 0 5px;
  flex: 0 0 auto;
  position: relative;
}

.calendar-block__col:before {
  content: "";
  position: absolute;
  left: 0;
  top: -17px;
  bottom: -17px;
  width: 1px;
  background-color: #daebef;
}

.calendar-block__select {
  padding: 0 0 0 2px;
  border: 0;
  width: auto;
  font-size: 12px;
  font-weight: 600;
}

.modal-calendar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: 345px;
  padding: 0;
  border: 2px solid #3dc7e8;
  box-shadow: 0px -3px 20px rgba(61, 199, 232, 0.08);
  border-bottom: 0;
  transition: 0.4s max-height;
}

.schedule-section {
  transition: 0.4s max-height;
  margin-bottom: 10px;
  padding: 0;
}

.schedule-section .section-collapse-icon {
}

.modal-calendar .calendar__body-rows::after {
  content: none;
}

.modal-calendar .calendar__body-rows {
  padding: 15px;
  align-items: flex-start;
}

.modal-calendar .calendar__body-head {
  font-weight: 500;
  font-size: 18px;
}

.calendar__body-btn {
  fill: none;
  font-size: 12px;
  color: #243341;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.calendar__body-btn svg {
  flex: 0 0 auto;
  margin-right: 8px;
}

.calendar__body-btn:after {
  content: attr(data-show);
  text-decoration: underline;
}

.modal-calendar--open .calendar__body-btn:after {
  content: attr(data-hidden);
}

.calendar__body-add {
  display: flex;
  align-items: center;
  fill: rgba(62, 200, 231, 1);
  stroke: rgba(62, 200, 231, 1);
  font-weight: 500;
  font-size: 18px;
}

.calendar__body-add svg {
  flex: 0 0 auto;
  margin-left: 14px;
}

.calendar__list {
  max-height: 280px;
  padding-right: 20px;
  max-width: 422px;
  margin-top: 13px;
  overflow: hidden auto;
  scrollbar-color: #6c7a7d transparent !important;
  scrollbar-width: thin !important;
}

.calendar__list::-webkit-scrollbar {
  width: 8px;
}

.calendar__list::-webkit-scrollbar-thumb {
  background-color: #6c7a7d;
  border-radius: 0;
}

.calendar__item:not(:last-of-type) {
  margin-bottom: 10px;
}

.modal-calendar .calendar__body-row:first-of-type {
  padding-top: 6px;
}

.checkbox {
  position: relative;
  margin-top: 18px;
}

.checkbox__part {
  font-size: 14px;
  line-height: 17px;
  color: #243341;
  font-weight: 400;
  padding-left: 28px;
  position: relative;
  display: block;
  cursor: pointer;
}

.checkbox__part:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #a9bbcb;
  border-radius: 5px;
  background-color: #fff;
}

.checkbox input:checked + .checkbox__part:before {
  border-color: #4cd2c7;
}

.checkbox input:checked + .checkbox__part:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 6px;
  border-bottom: 2px solid #4cd2c7;
  border-left: 2px solid #4cd2c7;
  left: 4px;
  top: 5px;
  transform: rotate(-45deg);
}

.modal-calendar--open .calendar__body-btn svg {
  transform: scaleY(-1);
}

.calendar--hidden {
  display: none;
}

.modal-calendar--open .calendar--hidden {
  display: block;
}

.conf-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.conf-list__item {
  padding: 15px;
  width: calc(100% / 4.5);
 
}

.conf-list__item-mobile {
  padding: 15px;
  width: calc(100%);
    
}

.card {
  max-width: 100%;
  width: 100%;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  border-radius: 3px;
  height: 100%;
  background-color: #fff;
  
}

.card__top {
  height: 360px;
  width: 100%;
  position: relative;
  border: 1px solid #daebef;
  background-color: #F4F6F8;
  
}

.card__label {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  border-radius: 6px;
  padding: 6px 8px;
  font-weight: 700;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
 display: flex;
    flex-direction: row;
    justify-content: start;
    vertical-align: center;
}

.card__body {
  padding: 30px;
}

.card__body .layout__inf {
  margin-bottom: 16px;
}

.card__body .layout__inf-media {
  border: 0;
  padding: 6px 6px 6px 0;
}

.card__body .layout__inf-blocks {
  border: 1px solid #daebef;
  border-radius: 10px;
  margin: 0;
  padding: 8px 15px 9px;
  margin-top: 19px;
  flex-wrap: wrap;
}

.card__body .layout__inf-block {
  padding: 0;
  width: auto;
}

.card__link {
  display: flex;
  align-items: center;
}

.card__link svg {
  flex: 0 0 auto;
  margin-right: 18px;
}

a.card__link:hover span {
  border-bottom: 1px solid transparent;
}

a.card__link span {
  font-size: 14px;
  line-height: 17px;
  border-bottom: 1px solid #243341;
}

.card__title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 12px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.breadcrumbs + .layout__contains {
  padding-top: 0;
}

.tbl {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  border-radius: 15px;
}

.tbl__body-row {
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  border-radius: 15px;
}

.tbl__body-row:not(:last-of-type) {
  margin-bottom: 10px;
}

.tbl__top {
  padding: 22px 0;
}

.tbl__items {
  display: flex;
  padding: 0 15px;
}

.tbl__item {
  padding: 0 15px;
  flex: 1;
}

.tbl__item--name {
  max-width: 288px;
}

.tbl__item--level {
  max-width: 200px;
}

select {
  display: block;
  width: 100%;
}

.tbl__item--level select {
  max-width: 128px;
}

.tbl__item--city select {
  max-width: 158px;
}

.tbl__item--text {
  max-width: 350px;
}

.tbl__item--text input {
  max-width: 255px;
}

.tbl__item--date {
  max-width: 284px;
  position: relative;
  padding-right: 60px !important;
}

.tbl__item--date input {
  background: url("../img/picker.svg") no-repeat calc(100% - 15px) center;
  max-width: 165px;
  padding-right: 40px;
}

.tbl__body .tbl__items {
  align-items: center;
}

.tbl__body .tbl__item {
  padding-top: 22px;
  padding-bottom: 22px;
}

.block-dropdown--green .block-dropdwon__btn {
  border: 1px solid #39dac7;
  color: #39dac7;
}

.block-dropdown--green .block-dropdwon__icon {
  stroke: #39dac7;
}

.block-dropdown--yellow .block-dropdwon__btn {
  border: 1px solid #d2bc7c;
  color: #d2bc7c;
}

.block-dropdown--yellow .block-dropdwon__icon {
  stroke: #d2bc7c;
}

.block-dropdown--purple .block-dropdwon__btn {
  border: 1px solid #5564e9;
  color: #5564e9;
}

.block-dropdown--purple .block-dropdwon__icon {
  stroke: #5564e9;
}

.block-dropdown--grey .block-dropdwon__btn {
  border: 1px solid #a9bbcb;
  color: #a9bbcb;
}

.block-dropdown--grey .block-dropdwon__icon {
  stroke: #a9bbcb;
}

.tbl__item-more {
  color: #a9bbcb;
  letter-spacing: 1px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
}

.tbl__item-more:before {
  content: "···";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sidebar {
  background-color: #0c2441;
  color: #fff;
  border-radius: 0 30px 30px 0;
  height: auto;
  min-height: 125vh;
  padding: 40px 20px;
  max-width: 315px;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.4s width;
}

.toggle {
  margin-right: 15px;
  cursor: pointer;
}
.toggle span {
  width: 24px;
  height: 2px;
  border-radius: 4px;
  background-color: #fff;
  display: block;
  margin-bottom: 7px;
}
.toggle span:last-child {
  margin-bottom: 0;
}

.sidebar-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 14px;
}

.link-icon {
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 20px;
  padding: 10px 10px;
}
.link-icon i {
  font-size: 24px;
  margin-right: 15px;
}

.list-submenu {
  padding: 2px;
  margin-top: 8px;
  border-radius: 8px;
  background-color: #0f2d51;
}
.list-submenu:last-child {
  margin-bottom: 0;
}
.list-submenu a {
  color: #fff;
}
.list-submenu .parent-submenu-link {
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  padding-bottom: 10px;
  display: block;
}
.list-submenu .link-icon {
  padding: 0;
  padding-bottom: 25px;
}
.list-submenu .submenu a {
  font-size: 13px;
  font-weight: 400;
  padding-left: 20px;
  position: relative;
  display: block;
  line-height: 16px;
}
.list-submenu .submenu a::before {
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  border-radius: 50%;
  position: absolute;
  left: 7px;
  top: 1.5px;
}
.list-submenu .submenu a:hover::before {
  border-color: #36b2f1;
}
.list-submenu .submenu li {
  margin-bottom: 10px;
  position: relative;
}
.list-submenu .submenu li:last-child {
  margin-bottom: 0;
}
.list-submenu ul li .submenu {
  padding-bottom: 40px;
}
.list-submenu ul li:last-child .submenu {
  padding-bottom: 0;
}

.sidebar nav a {
  color: #fff;
}
.sidebar nav a:hover {
  color: #36b2f1;
}

.icon-small {
  font-size: 20px !important;
}

.sidebar {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sidebar nav ul .link-toggle-submenu {
  position: relative;
  padding-bottom: 0;
}

.sidebar nav ul .link-toggle-submenu + ul {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  transition: opacity 0.1s;
}
.sidebar nav ul .link-toggle-submenu.open + ul {
  max-height: 100000px;
  visibility: visible;
  opacity: 1;
}
.sidebar nav ul .link-toggle-submenu.open {
  padding-bottom: 40px;
}
.sidebar nav ul .link-toggle-submenu.open::after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

#sidebar-nav {
  overflow: auto;
  min-height: 800px;
  height: 100%;
}

.simplebar-scrollbar::before {
  background-color: #36b2f1;
}

.simplebar-track.simplebar-vertical {
  width: 8px;
  right: 0;
  overflow-y: hidden;
}

.tabs-top {
  display: block;
}

.tabs-setting-conf-contents {
  padding: 45px 30px;
  background-color: #fff;
  border-radius: 0 0px 20px 20px;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  margin-bottom: 20px;
}

.tabs-setting-conf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.tabs-setting-conf .item {
  width: calc(25% - 20px);
  border-radius: 15px 15px 0 0;
  border: 1px solid #fff;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(1.13%, #f4f6f8),
    color-stop(98.87%, #eff2f4)
  );
  background: -o-linear-gradient(right, #f4f6f8 1.13%, #eff2f4 98.87%);
  background: linear-gradient(270deg, #f4f6f8 1.13%, #eff2f4 98.87%);
  padding: 15px 20px;
  padding-right: 50px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tabs-setting-conf .item .icon-tab {
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 19px;
  background: #24d8c2;
  border-radius: 9px;
  margin-right: 15px;
  min-width: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.tabs-setting-conf .item .icon-tab.offline {
  background: #3c4eed;
}
.tabs-setting-conf .item .icon-tab.gybrid {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3c4eed),
    to(#24d8c2)
  );
  background: -o-linear-gradient(left, #3c4eed 0%, #24d8c2 100%);
  background: linear-gradient(90deg, #3c4eed 0%, #24d8c2 100%);
}
.tabs-setting-conf .item.active {
  padding-top: 25px;
  background: #fff;
}
.tabs-setting-conf .item .close-tab {
  margin-left: 20px;
  font-size: 13px;
  position: absolute;
  right: 20px;
  color: #a9bbcb;
  cursor: pointer;
}
.tabs-setting-conf .item .close-tab:hover {
  color: #243341;
}
.tabs-setting-conf .item-options {
  width: 80px;
  padding: 22.5px;
  font-size: 20px;
  color: #a9bbcb;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.block {
  background: #fff;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  border-radius: 15px;
}
.block .header-block {
  padding: 30px;
  border-bottom: 1px solid #dbe6ef;
}
.block .header-block .title {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
}
.block .content-block {
  padding: 30px;
}

.settings-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.settings-block .item {
  width: calc(33% - 40px);
}
.settings-block .item .title {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}

.file-label {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  border: 1px dashed #dbe6ef;
  background: #f9fafb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  margin-bottom: 20px;
}
.file-label .edit-btns {
  position: absolute;
  right: 8px;
  top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.file-label .edit-btns .edit,
.file-label .edit-btns .delete {
  background: #ffffff;
  border: 1px solid #daebef;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  width: 32px;
  cursor: pointer;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 12px;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.file-label .edit-btns .edit:hover,
.file-label .edit-btns .delete:hover {
  background: #daebef;
}
.file-label .edit-btns .edit {
  margin-right: 10px;
}
.file-label .edit-btns .delete {
  color: #ff5c5c;
}
.file-label label {
  color: #36b2f1;
  position: relative;
  cursor: pointer;
  line-height: 17px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
.file-label label i {
  display: block;
  font-size: 70px;
  margin-bottom: 20px;
}
.file-label label span {
  text-decoration: underline;
}
.file-label .desc-file {
  font-size: 12px;
  color: #6c7a7d;
  display: block;
  text-align: center;
}
.file-label input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.textarea {
  width: 100%;
  height: 140px;
  border: 1px solid #a9bbcb;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  resize: none;
  color: #6c7a7d;
}

.h1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

.conf-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.conf-detail .conf-img {
  margin-right: 30px;
  max-width: 25%;
}
.conf-detail .conf-info .title {
  margin-bottom: 30px;
}
.conf-detail .conf-info .tags {
  margin-bottom: 25px;
}
.conf-detail .conf-info .tags li {
  display: inline-block;
  padding: 6px;
  border-radius: 6px;
  background-color: #d5dee8;
  text-transform: uppercase;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 0.1em;
  font-weight: 700;
  margin-right: 10px;
}
.conf-detail .conf-info .tags li:last-child {
  margin-right: 0;
}
.conf-detail .conf-info .meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.conf-detail .conf-info .meta .date,
.conf-detail .conf-info .meta .address {
  border-radius: 10px;
  border: 1px solid #daebef;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.conf-detail .conf-info .meta .date i,
.conf-detail .conf-info .meta .address i {
  font-size: 25px;
  margin-right: 20px;
}
.conf-detail .conf-info .meta .date span,
.conf-detail .conf-info .meta .address span {
  display: block;
}
.conf-detail .conf-info .meta .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}
.conf-detail .conf-info .meta .date .day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 30px;
  margin-right: 30px;
  position: relative;
}
.conf-detail .conf-info .meta .date .day::after {
  content: "";
  width: 1px;
  height: 34px;
  background-color: #a9bbcb;
  opacity: 0.2;
  position: absolute;
  right: 0;
}
.conf-detail .conf-info .meta .address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 100px;
}

.conf-links {
  margin-top: 20px;
  margin-left: 120px;
}
.conf-links .btn-icon {
  margin-bottom: 45px;
  min-width: 280px;
}
.conf-links .btn-icon:last-child {
  margin-bottom: 0;
}

file-label_info {
  color: #36b2f1;
  position: relative;
  cursor: pointer;
  line-height: 17px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.member-top {
  background-color: #fff;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
}
.member-top .conf-detail-member {
  padding: 15px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
}
.member-top .watch-online {
  width: 30%;
  min-width: 30%;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.member-top .watch-online a {
  color: #fff;
  text-align: center;
}
.member-top .watch-online a:hover {
  color: #36b2f1;
}
.member-top .watch-online a i {
  font-size: 60px;
}
.member-top .watch-online a span {
  display: block;
  margin-top: 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.5;
}

.btn-disabled {
  padding: 15px 25px;
  border: 1px solid #243341;
  color: #243341;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  text-transform: uppercase;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}

.btn-border {
  padding: 15px 25px;
  border: 1px solid #243341;
  color: #243341;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  text-transform: uppercase;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.btn-border i {
  font-size: 20px;
  margin-right: 15px;
}
.btn-border:hover {
  color: #fff;
  background: #243341;
}

.btn-member {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
}
.btn-member button {
  margin-right: 15px;
}
.btn-member .btn-gradient {
  padding: 20px 65px;
}

.btn-gradient {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(-2.55%, #1994d2),
    color-stop(104.08%, #36b2f1)
  );
  background: -o-linear-gradient(right, #1994d2 -2.55%, #36b2f1 104.08%);
  background: linear-gradient(270deg, #1994d2 -2.55%, #36b2f1 104.08%);
  border-radius: 10px;
  text-align: center;
  padding: 20px 30px;
}

.block-default {
  background-color: #fff;
  margin-top: 20px;
  padding: 30px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
}
.block-default .title {
  font-size: 22px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}
.block-default p {
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}
.block-default .title-small {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.partners ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap:wrap;
  max-width: 100%;
  
}
.partners ul li {
  margin-right: 55px;
  margin-top: 25px;
}
.partners ul li:last-child {
  margin-right: 0;
}

.tabs-client-content {
}

.tab-switcher {
  border: 1px solid #1994d2;
  border-radius: 10px;
  margin-bottom: 40px;
  display: inline-block;
}
.tab-switcher ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.tab-switcher ul li {
  padding: 16px 27px;
  border-radius: 9px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #fff;
}
.tab-switcher ul li.active {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(-2.55%, #1994d2),
    color-stop(104.08%, #36b2f1)
  );
  background: -o-linear-gradient(right, #1994d2 -2.55%, #36b2f1 104.08%);
  background: linear-gradient(270deg, #1994d2 -2.55%, #36b2f1 104.08%);
  color: #fff;
}

.tab-client-content {
  border: 1px solid #1994d2;
  border-radius: 10px;
  margin-bottom: 40px;
  display: inline-block;
  overflow-x: auto;
}

.list-schedule .item:hover {
    border: 1px solid #36b2f1;    
}

.list-schedule .item {
  border-radius: 10px;
  border: 1px solid #daebef;
  background-color: #f4f6f8;
  padding: 20px;
  min-width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.list-schedule .item:last-child {
  margin-bottom: 0;
}
.list-schedule .item .time {
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  letter-spacing: 0.15em;
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
}
.list-schedule .item .time::after {
  content: "";
  width: 1px;
  height: calc(100% + 20px);
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #daebef;
  position: absolute;
  right: 0;
}
.list-schedule .item.inactive {
  color: #6c7a7d;
}
.list-schedule .item .theme {
  width: 100%;
}
.list-schedule .item .title-theme {
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  padding-bottom: 5px;
}
.list-schedule .item .desc-theme {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}
.list-schedule .item .favorite {
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.list-schedule .item .favorite::before {
  font-size: 30px;
  font-family: "naviconf";
}
.list-schedule .item .favorite path {
  fill: #fff0;
}
.list-schedule .item .favorite.active path {
  fill: #faff00;
}

.noauth-page {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.noauth-page form {
  width: 600px;
  margin: 0 auto; 
  padding-top: 30vh;
}

.noauth-page .MuiTextField-root {
  margin-top: 10px;
}

.noauth-page .form-button {
  margin-top: 30px;
}

.forgot-password-link {
  font-size: 16px;
  margin-top: 10px;
}

.conference-not-selected {
  display: flex;
  justify-content: center;
}

.conference-not-selected label.form__label {
  margin-top: 15px;
  font-size: 20px;
  
}